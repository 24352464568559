<template>
  <div class='content-container flex flex-col justify-start items-stretch'>
    <portal to='headerSpace'>
      <div class='flex flex-row justify-start items-center gap-x-4'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>현금배당</h1>
      </div>
    </portal>
    <main class='w-full flex flex-row justify-start items-stretch relative'
      style='height: calc(100vh - 100px);'>
      <div
        class='border-r block bg-gray-50 w-60 lg:w-80 flex-shrink-0 border-r shadow-md z-10 pb-2 flex flex-col justify-start items-stretch absolute lg:relative'
        style='width: 18rem;'>
        <div class='pt-2 px-2 sticky top-0 bg-gray-50 flex-shrink-0'>
          <div class='flex flex-row w-full'>
            <el-radio-group
              v-model='collectStatus'
              size='large'>
              <el-radio-button label='전체'></el-radio-button>
              <el-radio-button label='진행중'></el-radio-button>
              <el-radio-button label='회수완료'></el-radio-button>
            </el-radio-group>
          </div>
          <input
            v-model='filterItemName'
            type='search'
            placeholder='종목명'
            class='w-full text-sm border px-2 my-1 py-2 rounded'>
        </div>
        <div class='flex-grow overflow-y-auto'>
          <div v-for='itemName in nameKeys' :key='itemName'
            class='px-2 lg:px-4 py-3 flex flex-row justify-between items-center'
            :class='isSelectedItemName(itemName)'
            @click='selectItemName(itemName)'>
            <span class='text-xs'>
              {{ itemName }}
            </span>
          </div>
        </div>
      </div>
      <pi-div-table v-if='selectedItemName'
        :item-name='selectedItemName'
        :share-code='selectedShareCode'
        class='flex-grow h-full' />
      <div v-else class='bg-gray-200 flex-grow grid place-items-center h-full'>
        <div class='text-gray-600'>Please Select A Item Data</div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PiDivTable from '@/views/PiDivTable.vue'
import uniq from 'lodash/uniq'

export default {
  name: 'PiDivs',
  components: {
    PiDivTable,
  },
  data () {
    return {
      collectStatus: '진행중',
      selectedItemName: '',
      selectedShareCode: '',
      filterItemName: '',
    }
  },
  watch: {
    'collectStatus': {
      handler: function (newVal) {
        if (newVal) {
          this.selectedItemName = ''
        }
      }
    }
  },
  computed: {
    ...mapState('piPortfolios', [
      'tmsPiDivs',
    ]),
    filteredStatus () {
      switch (this.collectStatus) {
        case '전체':
          return this.tmsPiDivs
        case '진행중':
          return this.tmsPiDivs.filter(div => div.holdingQuantity !== 0)
        case '회수완료':
          return this.tmsPiDivs.filter(div => div.holdingQuantity === 0)
        default:
          return []
      }
    },
    filteredData () {
      if (this.filterItemName === '') {
        return this.filteredStatus
      } else {
        return this.filteredStatus.filter(div => div.name.toUpperCase().includes(this.filterItemName.toUpperCase()))
      }
    },
    nameKeys () {
      return uniq(this.filteredStatus.map(fill => fill.name)).sort((a, b) => a > b ? 1 : -1)
    },
  },
  methods: {
    ...mapActions('piPortfolios', [
      'getTmsPiDivs',
    ]),
    selectItemName (itemName) {
      this.selectedItemName = itemName

      let found = this.filteredStatus.find(div => div.name === itemName)
      this.selectedShareCode = found ? found.shareCode : ''
    },
    isSelectedItemName (itemName) {
      return itemName === this.selectedItemName ? 'border-l-2 border-black bg-blue-50 font-semibold' : 'border-l-2 border-transparent hover:bg-gray-100 cursor-pointer'
    },
  },
  mounted () {
    this.getTmsPiDivs()
  },
}
</script>
